.image-swiper .swiper {
  position: relative;

  .swiper-pagination {
    position: absolute !important;
    bottom: 0.5rem !important;
  }
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  position: relative !important;
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  margin-top: 0.825rem;
}

.swiper-slide {
  height: auto !important;
}

.swiper-pagination-bullet-active {
  background: #CB3F60 !important;
}
